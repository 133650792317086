import API from '@apis/client';
import { AxiosResponse } from 'axios';
import { getPushPayload, pushPaylaod } from 'models/Supplement';
import {
  GetPushData,
  GetPushError,
  PushPartialUpdateData,
  PushPartialUpdateError,
  PushPatchCheckPushAllData,
  PushPatchCheckPushAllError,
  PushReadData,
  PushReadError,
  PushStatusPartialUpdateData,
  PushStatusPartialUpdateError,
  PushStatusReadData,
  PushStatusReadError,
} from 'models/swagger/data-contracts';

/**
 *  푸시 목록 가져오기 API
 *  @function getPushApi
 */
export const getPushApi = ({ cursor, limit }: getPushPayload): Promise<AxiosResponse<GetPushData, GetPushError>> => {
  return API.get(`/push?cursor=${cursor}&limit=${limit}`);
};

/**
 *  푸시 상세 조회 API
 *  @function getPushDetailApi
 *  @param {number} push_id push id
 */
export const getPushDetailApi = ({ push_id }: pushPaylaod): Promise<AxiosResponse<PushReadData, PushReadError>> => {
  return API.get(`/push/${push_id}`);
};

/**
 *  특정 푸시 읽음 처리 API
 *  @function patchPartialPushApi
 *  @param {number} push_id push id
 */
export const patchPartialPushApi = ({
  push_id,
}: pushPaylaod): Promise<AxiosResponse<PushPartialUpdateData, PushPartialUpdateError>> => {
  return API.patch(`/push/${push_id}`);
};

/**
 *  모든 푸시 읽음 처리 API
 *  @function patchAllPushApi
 */
export const patchAllPushApi = (): Promise<AxiosResponse<PushPatchCheckPushAllData, PushPatchCheckPushAllError>> => {
  return API.patch(`/push/all`);
};

/**
 *  최근 푸시 읽음 상태 확인 API
 *  @function getPushStatusApi
 */
export const getPushStatusApi = (): Promise<AxiosResponse<PushStatusReadData, PushStatusReadError>> => {
  return API.get(`/push/status`);
};

/**
 *  최근 푸시 읽음 상태 업데이트 API
 *  @function patchPushStatusApi
 */
export const patchPushStatusApi = (): Promise<
  AxiosResponse<PushStatusPartialUpdateData, PushStatusPartialUpdateError>
> => {
  return API.patch(`/push/status`);
};
