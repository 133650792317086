import { useMemo } from 'react';
import { IconName, icons } from './icons';
import { IconSize } from '@constants/atoms';

type Props = Styleable & {
  readonly size?: IconSize;
  readonly width?: number;
  readonly height?: number;
  readonly name: IconName;
  readonly fill?: string;
  readonly stroke?: string;
  readonly onClick?: () => void;
  readonly steelbosoId?: string;
};

export default function Icon({
  size = IconSize.MEDIUM,
  width,
  height,
  style,
  name,
  fill = 'transparent',
  stroke,
  onClick,
  steelbosoId,
}: Props) {
  const Component = icons[name] || null;

  // cursor type
  const Pointer = 'pointer';
  const Default = 'default';

  const cursor = useMemo(() => {
    if (!!onClick) {
      return Pointer;
    }

    return Default;
  }, [onClick]);

  return (
    <Component
      style={{ width: width ? width : size, height: height ? height : size, fill, stroke, cursor, ...style }}
      onClick={onClick}
      data-steelboso-id={steelbosoId}
    />
  );
}
