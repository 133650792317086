import Icon from '@components/_atoms/Icon';
import { ButtonSize, ButtonType, TypoVariant } from '@constants/atoms';
import { ButtonColor, IconSize } from '@constants/atoms';
import styled from '@emotion/styled';
import { useGetButtonColor } from '@hooks/atoms/useGetButtonColor';
import { Radius } from '@styles/Radius';
import { MOBILE_MODE } from '@constants/size';
import { useIsMobile } from '@hooks/responsive/useIsMobile';
import Typography from '../Typography';
import { RefObject } from 'react';
import SpinnerWhiteLottie from '@public/assets/spinner/white.json';
import { useGetButtonSize } from '@hooks/atoms/useGetButtonSize';
import dynamic from 'next/dynamic';

// Lottie animation
const Lottie = dynamic(() => import('lottie-react'), {
  ssr: false,
});

type Props = Styleable & {
  readonly width?: string;
  readonly type?: ButtonType;
  readonly color?: ButtonColor;
  readonly size?: ButtonSize;
  readonly text?: string;
  readonly disabled?: boolean;
  readonly arrow?: boolean;
  readonly onClick?: () => void;
  readonly buttonRef?: RefObject<HTMLButtonElement>;
  readonly isLoading?: boolean;
  readonly steelbosoId?: string;
};

type CssProps = {
  readonly arrow: boolean;
  readonly background: string;
  readonly textColor: string;
  readonly borderColor: string;
  readonly hoverBackgroundColor: string;
  readonly hoverTextColor: string;
  readonly hoverBorderColor: string;
  readonly paddingLeftRight: string;
  readonly paddingTopBottom: string;
};

const ButtonLayout = styled.button<CssProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  padding: ${({ arrow, paddingLeftRight, paddingTopBottom }) =>
    arrow ? `${paddingTopBottom} 15px 12px ${paddingLeftRight}` : `${paddingTopBottom} ${paddingLeftRight}`};
  border-radius: ${Radius.MEDIUM};
  border: 1px solid;
  cursor: pointer;

  background-color: ${({ background }) => background};
  color: ${({ textColor }) => textColor};
  border-color: ${({ borderColor }) => borderColor};

  &:hover {
    background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
    color: ${({ hoverTextColor }) => hoverTextColor};
    border-color: ${({ hoverBorderColor }) => hoverBorderColor};
  }

  &:disabled {
    cursor: not-allowed;
  }

  /* mobile -> small */
  @media ${MOBILE_MODE} {
    padding: ${({ arrow }) => (arrow ? '9px 7px 9px 15px' : '9px 15px')};
  }
`;
const ArrowIcon = styled(Icon)``;
const Text = styled(Typography)``;

export default function Button({
  width,
  type = ButtonType.Submit,
  style,
  color = ButtonColor.ContainedBlue,
  size = ButtonSize.Large,
  text,
  disabled = false,
  arrow = false,
  onClick,
  buttonRef,
  isLoading = false,
  steelbosoId,
}: Props) {
  const [background, textColor, borderColor, hoverBackgroundColor, hoverTextColor, hoverBorderColor] =
    useGetButtonColor(color, disabled);
  const [pcFontSize, mobileFontSize, padding] = useGetButtonSize(size);
  const [paddingTopBottom, paddingLeftRight] = padding.split(' ');
  const isMobile = useIsMobile();
  const spinnerSize = isMobile ? '20' : '24';

  return (
    <ButtonLayout
      style={{ ...style, width }}
      ref={buttonRef}
      type={type}
      arrow={arrow}
      disabled={disabled}
      background={background}
      textColor={textColor}
      borderColor={borderColor}
      hoverBackgroundColor={hoverBackgroundColor}
      hoverTextColor={hoverTextColor}
      hoverBorderColor={hoverBorderColor}
      paddingLeftRight={paddingLeftRight}
      paddingTopBottom={paddingTopBottom}
      onClick={onClick}
      data-steelboso-id={steelbosoId}>
      {isLoading ? (
        <Lottie animationData={SpinnerWhiteLottie} style={{ width: `${spinnerSize}px`, height: `${spinnerSize}px` }} />
      ) : (
        <>
          <Text
            style={{ whiteSpace: 'nowrap' }}
            variant={isMobile ? (mobileFontSize as TypoVariant) : (pcFontSize as TypoVariant)}
            color={textColor}>
            {text}
          </Text>
          {arrow && <ArrowIcon name="arrow-right" fill={background} stroke={textColor} size={IconSize.SMALL} />}
        </>
      )}
    </ButtonLayout>
  );
}
