import Icon from '@components/_atoms/Icon';
import { IconName } from '@components/_atoms/Icon/icons';
import { IconSize } from '@constants/atoms';
import styled from '@emotion/styled';
import { ForwardedRef, forwardRef } from 'react';

type Props = Styleable & {
  readonly size?: IconSize;
  readonly width?: number;
  readonly height?: number;
  readonly name: IconName;
  readonly fill?: string;
  readonly stroke?: string;
  readonly onClick?: () => void;
  readonly steelbosoId?: string;
};

const RefIconWrapper = styled.div`
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const RefIcon = (
  { size = IconSize.MEDIUM, name, fill = 'transparent', stroke, onClick, steelbosoId, width, height }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <RefIconWrapper
      style={{ width: width ? width : size, height: height ? height : size }}
      ref={ref}
      onClick={onClick}
      data-steelboso-id={steelbosoId}>
      <Icon
        size={size}
        width={width}
        height={height}
        name={name}
        fill={fill}
        stroke={stroke}
        style={{ pointerEvents: 'none' }}
      />
    </RefIconWrapper>
  );
};

export default forwardRef(RefIcon);
