import { RefObject, useEffect } from 'react';

export default function useHandleOutsideClickAction(refArray: RefObject<HTMLElement>[], callback: () => void) {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // 모든 ref 요소들 중 하나라도 클릭된 요소를 포함하고 있다면 내부 클릭으로 간주
      const isOutsideClick = refArray.every((ref) => {
        // ref.current가 없는 경우는 외부 클릭으로 처리
        if (!ref.current) return true
        // ref.current가 클릭된 요소를 포함하지 않으면 외부 클릭
        return !ref.current.contains(event.target as Node)
      })

      if (isOutsideClick) {
        callback()
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [refArray, callback]);
}
